import React from 'react';
import {
  Card,
  Field,
  HorizontalGroup,
  Icon,
  Input,
  LoadingPlaceholder,
  Modal,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import { dateTimeAsMoment } from '@grafana/data';

import { getStyles } from 'utils/styles';
import { useLicensesQuery } from '__generated__/__admin';
import { notEmpty } from 'helpers';

interface Props {
  id?: string;
  onDismiss: () => void;
}

export const LicenseDetails: React.FC<Props> = ({ id, onDismiss }) => {
  const { table } = useStyles2(getStyles());
  const { data, loading } = useLicensesQuery({ variables: { where: { id: { eq: id } } } });

  if (loading) {
    return <LoadingPlaceholder text="Loading license details" />;
  }

  const license = data?.configLicenses?.filter(notEmpty)[0];
  const activeLicense =
    license?.expirationDate === null ||
    dateTimeAsMoment(license?.expirationDate).isSameOrAfter(dateTimeAsMoment(), 'day');

  return (
    <Modal closeOnEscape isOpen={id !== undefined} title="License Details" onDismiss={onDismiss}>
      <VerticalGroup>
        <Card>
          <Card.Heading>License</Card.Heading>
          <Card.Description>
            <HorizontalGroup wrap={true}>
              <Field label="Installation ID">
                <DisabledInput value={license?.installationId} />
              </Field>
              <Field label="Vessel">
                <DisabledInput value={license?.vessel?.name} />
              </Field>
              <Field label="Status">
                <DisabledInput
                  value={activeLicense ? 'Valid' : 'Expired'}
                  suffix={
                    <Icon
                      name={activeLicense ? 'check-circle' : 'exclamation-circle'}
                      style={activeLicense ? { color: 'green' } : { color: 'red' }}
                    />
                  }
                />
              </Field>
              <Field label="Created Date">
                <DisabledInput value={dateTimeAsMoment(license?.createdAt).format('DD-MM-YYYY')} />
              </Field>
              <Field label="Expiration Date">
                <DisabledInput
                  value={license?.expirationDate == null ? 'Never' : dateTimeAsMoment(license?.expirationDate).format('DD-MM-YYYY')}
                />
              </Field>
              <Field label="Created By">
                <DisabledInput value={license?.createdBy} />
              </Field>
              {license?.comment && (
                <Field label="Comment">
                  <>{license?.comment}</>
                </Field>
              )}
            </HorizontalGroup>
          </Card.Description>
        </Card>
        {license?.users?.length && (
          <Card>
            <Card.Heading>Users</Card.Heading>
            <Card.Description>
              <table className={table}>
                <tr>
                  <th>Username</th>
                  <th>Password</th>
                </tr>
                {license.users.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <DisabledInput value={user?.username} />
                    </td>
                    <td>
                      <DisabledInput value={user?.password} />
                    </td>
                  </tr>
                ))}
              </table>
            </Card.Description>
          </Card>
        )}
      </VerticalGroup>
    </Modal>
  );
};

const DisabledInput: React.FC<{ value?: string | null; suffix?: React.ReactNode }> = ({ value, suffix }) => (
  <Input value={value ?? undefined} disabled width={25} suffix={suffix} />
);
