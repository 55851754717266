import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Table, useTheme2, HorizontalGroup, IconButton, Icon, useStyles2 } from '@grafana/ui';
import { FieldType, MutableDataFrame, applyFieldOverrides, dateTime } from '@grafana/data';
import { AllVesselsQuery } from '__generated__/__admin';
import { getStyles } from 'utils/styles';
import { field } from 'utils/field';
import { humanizeApplicationNameHelper } from 'utils/helpers';

interface Props {
  onEdit: (id: string) => void;
  data?: AllVesselsQuery;
  usePagination: boolean;
}

export const VesselsTable: FC<Props> = ({ onEdit, data, usePagination }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles());

  const dataFrame = useMemo(() => {
    const td: MutableDataFrame = new MutableDataFrame({
      fields: [
        field<string>('Action', { width: 120, type: FieldType.string, sortable: false }),
        field<string>(' ', { width: 10, sortable: false }),
        field<string>('ID', { width: 80 }),
        field<string>('IMO', { width: 80 }),
        field<string>('Name', { width: 200 }),
        field<string>('Company', { width: 200 }),
        field<string>('Application Type', { width: 175 }),
        field<string>('Version', { width: 120 }),
        field<string>('Project', { width: 80 }),
        field<string>('FAT Date', { width: 100 }),
        field<string>('Startup Date', { width: 100 }),
        field<string>('Comment', { type: FieldType.string, sortable: false }),
      ],
    });

    data?.vessels?.forEach((vessel) => {
      if (vessel) {
        td.appendRow([
          <VesselAction key={vessel.id} id={vessel.id} onEdit={onEdit} />,
          vessel.deleted ? (
            <Icon key={`${vessel.id}-info`} title={'Vessel is hidden from customer'} name="eye-slash" />
          ) : undefined,
          vessel.id,
          vessel.imo,
          vessel.name,
          vessel.company?.companyName,
          humanizeApplicationNameHelper(vessel.applicationType),
          vessel.version,
          vessel.projectNumber,
          vessel.fatDate ? dateTime(vessel.fatDate).format('DD.MM.YYYY') : '',
          vessel.startupDate ? dateTime(vessel.startupDate).format('DD.MM.YYYY') : '',
          vessel.comment,
        ]);
      }
    });
    return applyFieldOverrides({
      data: [td],
      fieldConfig: {
        overrides: [],
        defaults: {},
      },
      theme,
      replaceVariables: (value: string) => value,
    })[0];
  }, [data, onEdit, theme]);

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <AutoSizer disableHeight className={styles.spacing}>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            return (
              <div style={{ width }}>
                <Table height={700} width={width - 40} enablePagination={usePagination} data={dataFrame} />
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

const VesselAction: React.FC<{ id: string; onEdit: (id: string) => void }> = ({ id, onEdit }) => {
  const history = useHistory();
  return (
    <div style={{ marginLeft: '5px' }}>
      <HorizontalGroup height={30}>
        <IconButton style={{ cursor: 'pointer' }} name="eye" onClick={() => onEdit(id)} tooltip="Edit" />
        <IconButton
          style={{ cursor: 'pointer' }}
          name="key-skeleton-alt"
          onClick={() => {
            history.push({ search: `?tab=licenses&vesselId=${id}` });
          }}
          tooltip="View licenses"
        />
        <IconButton
          style={{ cursor: 'pointer' }}
          name="cog"
          onClick={() => {
            history.push({ search: `?tab=monSysConfigs&vesselId=${id}` });
          }}
          tooltip="View config"
        />
      </HorizontalGroup>
    </div>
  );
};
