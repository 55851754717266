import React, { FC, useMemo, useState } from 'react';
import { HorizontalGroup, IconButton, Table, useStyles2, useTheme2 } from '@grafana/ui';
import { MutableDataFrame, applyFieldOverrides, dateTime, dateTimeAsMoment } from '@grafana/data';
import { LicensesQuery } from '__generated__/__admin';
import { getStyles } from 'utils/styles';
import { field } from 'utils/field';
import { notEmpty } from 'helpers';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LicenseDetails } from './LicenseDetails';

interface Props {
  data?: LicensesQuery;
  downloadAction: (id: string) => void;
  usePagination: boolean;
}

export const LicenseTable: FC<Props> = ({ data, downloadAction, usePagination }) => {
  const theme = useTheme2();
  const style = useStyles2(getStyles());
  const [editId, setEditId] = useState<string>();

  const tableData = useMemo(() => {
    const td: MutableDataFrame = new MutableDataFrame({
      fields: [
        field<string>('Action', { sortable: false, width: 80 }),
        field<string>('Status'),
        field<string>('Vessel'),
        field<string>('Installation ID'),
        field<string>('Created Date'),
        field<string>('Expiration Date'),
        field<string>('Created By'),
        field<string>('Comment', { sortable: false }),
      ],
    });
    data?.configLicenses?.filter(notEmpty).forEach((license) => {
      td.appendRow([
        <LicenseActions
          key={license.id}
          id={license.id}
          onDownload={(id) => downloadAction(id)}
          onEdit={(id) => setEditId(id)}
        />,
        FormatDateStatus(license.expirationDate),
        license.vessel?.name,
        license.installationId,
        dateTime(license.createdAt).format('DD.MM.YYYY'),
        license.expirationDate ? dateTime(license.expirationDate).format('DD.MM.YYYY') : 'Never expires',
        license.createdBy,
        license.comment,
      ]);
    });
    return td;
  }, [data, downloadAction]);

  const dataFrame = useMemo(() => {
    return applyFieldOverrides({
      data: [tableData],
      fieldConfig: {
        overrides: [],
        defaults: {},
      },
      timeZone: 'utc',
      theme,
      replaceVariables: (value: string) => value,
    })[0];
  }, [tableData, theme]);

  return (
    <>
      {editId && <LicenseDetails id={editId} onDismiss={() => setEditId(undefined)} />}
      <div className={style.wrap}>
        <div className={style.content}>
          <AutoSizer disableHeight className={style.spacing}>
            {({ width }) => {
              if (width === 0) {
                return null;
              }
              return (
                <div style={{ width }}>
                  <Table
                    height={700}
                    width={width}
                    enablePagination={usePagination}
                    data={dataFrame}
                    initialSortBy={[{ displayName: 'Expiration Date', desc: true }]}
                  />
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </>
  );
};

function FormatDateStatus(input: Date | null): string {
  if (input === null || dateTimeAsMoment(input).isSameOrAfter(dateTimeAsMoment(), 'day')) {
    return 'Valid';
  }
  return 'Expired';
}

const LicenseActions: React.FC<{ id: string; onEdit: (id: string) => void; onDownload: (id: string) => void }> = ({
  id,
  onDownload,
  onEdit,
}) => (
  <div style={{ marginLeft: '5px' }}>
    <HorizontalGroup key={id} height={30}>
      <IconButton key={id} name="eye" tooltip={'Details'} onClick={() => onEdit(id)} />
      <IconButton key={id} name="download-alt" tooltip={'Download license file'} onClick={() => onDownload(id)} />
    </HorizontalGroup>
  </div>
);
