import React, { FC, useState, useMemo } from 'react';

import { AppRootProps } from '@grafana/data';
import { Button, HorizontalGroup } from '@grafana/ui';

import { AddCompany } from 'components/company/AddCompany';
import { CompaniesTable } from 'components/company/CompaniesTable';
import { Filter } from 'components/filter/Filter';
import { CompanyFilterInput, InputMaybe, SortEnumType, useCompaniesQuery } from '__generated__/__admin';
import { filterToWhere } from 'utils/filter';
import { Mutable } from 'types';

export const CompanyPage: FC<AppRootProps> = () => {
  const [companyFilter, setCompanyFilter] = useState<string>();
  const [grafanaOrgFilter, setGrafanaOrgFilter] = useState<string>();
  const [usePagination, setUsePagination] = useState(true);

  const where = useMemo<InputMaybe<CompanyFilterInput> | undefined>(() => {
    const companyWhere = filterToWhere(companyFilter);
    const grafanaOrgWhere = grafanaOrgFilter ? { eq: parseInt(grafanaOrgFilter, 10) } : undefined;

    const res: InputMaybe<Mutable<CompanyFilterInput>> = {};
    if (companyWhere) {
      res.companyName = companyWhere;
    }
    if (grafanaOrgWhere) {
      res.grafanaOrgId = grafanaOrgWhere;
    }
    if (Object.keys(res).length === 0) {
      return undefined;
    }
    return res as InputMaybe<CompanyFilterInput>;
  }, [companyFilter, grafanaOrgFilter]);

  const { data } = useCompaniesQuery({ variables: { order: [{ companyName: SortEnumType.ASC }], where } });

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <HorizontalGroup justify="space-between">
          <AddCompany />
          <HorizontalGroup>
            <Filter<string> label="Name" setFilter={(v) => setCompanyFilter(v)} filter={companyFilter || ''} />
            <Filter<string>
              label="Grafana Org"
              setFilter={(v) => setGrafanaOrgFilter(v)}
              filter={grafanaOrgFilter || ''}
            />
            <Button onClick={() => setUsePagination(!usePagination)}>{usePagination ? 'Show all' : 'Show limited'}</Button>
          </HorizontalGroup>
        </HorizontalGroup>
      </div>
      <CompaniesTable data={data} usePagination = {usePagination}/>
    </>
  );
};
